import { useState, useEffect, useRef, useMemo } from 'react'
import {
  format,
  subMonths,
  addMonths,
  subYears,
  addYears,
  isEqual,
  getDaysInMonth,
  getDay,
  startOfWeek,
  addDays
} from 'date-fns'
import { useFormContext } from 'react-hook-form'

import { CalendarDaysIcon, ExclamationCircleIcon, XCircleIcon, ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/solid'

import { twMerge } from 'tailwind-merge'
import { useTranslation } from 'react-i18next'
import { InformationCircleIcon } from '@heroicons/react/20/solid'

type DatepickerType = 'date' | 'month' | 'year'

type InputDateProps = {
  id: string
  label?: string
  placeholder?: string
  readOnly?: boolean
  className?: string
  canReset?: boolean
  required?: boolean
  tooltip?: string
}

const InputDate = ({ id, label, placeholder, readOnly, className, canReset, required, tooltip }: InputDateProps) => {
  const ref = useRef(null)
  const { t } = useTranslation()
  const { setValue, watch, resetField, formState: { errors } } = useFormContext()

  const DAYS = useMemo(() => {
    const monday = startOfWeek(new Date())
    return Array.from(Array(7).keys()).map(dayToAdd => format(addDays(monday, dayToAdd), 'EE'))
  }, [])
  const [dayCount, setDayCount] = useState<Array<number>>([])
  const [blankDays, setBlankDays] = useState<Array<number>>([])
  const [showDatepicker, setShowDatepicker] = useState(false)
  const [datepickerHeaderDate, setDatepickerHeaderDate] = useState<Date>(watch(id) ? new Date(watch(id)) : new Date())
  const [type, setType] = useState<DatepickerType>('date')

  const decrement = () => {
    switch (type) {
      case 'date':
        setDatepickerHeaderDate((prev) => subMonths(prev, 1))
        break
      case 'month':
        setDatepickerHeaderDate((prev) => subYears(prev, 1))
        break
      case 'year':
        setDatepickerHeaderDate((prev) => subYears(prev, 1))
        break
    }
  }

  const increment = () => {
    switch (type) {
      case 'date':
        setDatepickerHeaderDate((prev) => addMonths(prev, 1))
        break
      case 'month':
        setDatepickerHeaderDate((prev) => addYears(prev, 1))
        break
      case 'year':
        setDatepickerHeaderDate((prev) => addYears(prev, 1))
        break
    }
  }

  const isToday = (date: number) => {
    const dateToTest = watch(id) || new Date()
    return datepickerHeaderDate.getFullYear() === dateToTest.getFullYear() && datepickerHeaderDate.getMonth() === dateToTest.getMonth() && date === dateToTest.getDate()
  }

  const isToday2 = (date: number) => {
    const dateToTest = new Date()
    return datepickerHeaderDate.getFullYear() === dateToTest.getFullYear() && datepickerHeaderDate.getMonth() === dateToTest.getMonth() && date === dateToTest.getDate()
  }

  const setDateValue = (date: number | undefined) => {
    setValue(id, date
      ? new Date(Date.UTC(
        datepickerHeaderDate.getFullYear(),
        datepickerHeaderDate.getMonth(),
        date
      ))
      : undefined, { shouldDirty: true })
    if (!date) {
      resetField(id)
    }
    setShowDatepicker(false)
  }

  const getDayCount = (date: Date) => {
    const daysInMonth = getDaysInMonth(date)

    // find where to start calendar day of week
    const dayOfWeek = getDay(new Date(date.getFullYear(), date.getMonth(), 1))
    const blankdaysArray = []
    for (let i = 1; i <= dayOfWeek - 1; i++) {
      blankdaysArray.push(i)
    }

    const daysArray = []
    for (let i = 1; i <= daysInMonth; i++) {
      daysArray.push(i)
    }

    setBlankDays(blankdaysArray)
    setDayCount(daysArray)
  }

  const isSelectedMonth = (month: number) =>
    isEqual(
      month,
      datepickerHeaderDate.getMonth()
    )

  const isSelectedYear = (year: number) =>
    isEqual(
      year,
      datepickerHeaderDate.getFullYear()
    )

  const setMonthValue = (month: number) => () => {
    setDatepickerHeaderDate(
      new Date(
        datepickerHeaderDate.getFullYear(),
        month,
        datepickerHeaderDate.getDate()
      )
    )
    setType('date')
  }

  const setYearValue = (year: number) => () => {
    setDatepickerHeaderDate(
      new Date(
        year,
        datepickerHeaderDate.getMonth(),
        datepickerHeaderDate.getDate()
      )
    )
    setType('date')
  }

  const toggleDatepicker = () => setShowDatepicker((prev) => !prev)

  const showMonthPicker = () => setType('month')

  const showYearPicker = () => setType('year')

  useEffect(() => {
    getDayCount(datepickerHeaderDate)
  }, [datepickerHeaderDate])

  useEffect(() => {
    function handleClickOutside (event: any) {
      if (ref.current && !((ref.current as any).contains(event.target))) {
        setShowDatepicker(false)
        setType('date')
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref])

  return (
    <div className={twMerge('w-full relative', className)} ref={ref}>
      {label
        ? <label htmlFor={id} className="text-sm font-medium text-gray-700 mb-1 pl-1 flex items-center gap-0.5">
            <span>{label}</span>
            {required ? <span>*</span> : null}
            {tooltip
              ? <InformationCircleIcon
                  className='h-4 w-4 ml-1'
                  data-tooltip-id='tooltip'
                  data-tooltip-content={tooltip}
                  data-tooltip-place='bottom'
                  />
              : null
            }
          </label>
        : null
      }
      <div className={twMerge('relative w-full h-10 flex items-center justify-between px-3 rounded-md border border-gray-300 bg-white text-left shadow-sm focus:border-primary focus:outline-none focus:ring-1 focus:ring-primary sm:text-sm', readOnly ? 'border-0 ring-0 shadow-none' : 'cursor-pointer')} onClick={readOnly ? undefined : toggleDatepicker}>
        <span className={`text-sm rounded-md ${watch(id) ? '' : 'text-gray-400'}`}>
          {watch(id) ? format(watch(id), 'dd/MM/yyyy') : !readOnly ? (placeholder || t('data.placeholder')) : ''}
        </span>

        {watch(id) && !readOnly && canReset
          ? <div className="absolute inset-y-0 right-10 flex items-center cursor-pointer" onClick={(e) => {
            e.stopPropagation()
            setDateValue(undefined)
          }}>
              <XCircleIcon className="h-5 w-5 text-gray-300" aria-hidden="true" />
            </div>
          : null
        }

        {!readOnly
          ? (errors as any)?.[id]
              ? <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
              : <CalendarDaysIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
          : null
        }
      </div>

      {(errors as any)?.[id]
        ? <p className="mt-1 mx-2 text-xs text-red-600" id={`${id}-error`}>
              {(errors as any)?.[id]?.message}
            </p>
        : null
      }

      {showDatepicker && (
        <div
          className="bg-white w-44 rounded-b-lg z-50 shadow p-4 absolute top-10 right-0 border border-gray-100 min-w-[300px]"
        >
          <div className="flex justify-between items-center mb-2">
            <div>
              <button
                type="button"
                className="transition ease-in-out duration-100 inline-flex cursor-pointer hover:bg-gray-100 p-1 rounded-full"
                onClick={decrement}
              >
                <ChevronLeftIcon className="h-4 w-4 text-gray-500" />
              </button>
            </div>
            {type === 'date' && (
              <div
                onClick={showMonthPicker}
                className="flex-grow p-1 text-md font-medium text-gray-700 cursor-pointer hover:bg-gray-100 rounded-lg"
              >
                <p className="text-center">
                  {format(datepickerHeaderDate, 'MMMM')}
                </p>
              </div>
            )}
            <div
              onClick={showYearPicker}
              className="flex-grow p-1 text-md font-medium text-gray-700 cursor-pointer hover:bg-gray-100 rounded-lg"
            >
              <p className="text-center">
                {format(datepickerHeaderDate, 'yyyy')}
              </p>
            </div>
            <div>
              <button
                type="button"
                className="transition ease-in-out duration-100 inline-flex cursor-pointer hover:bg-gray-100 p-1 rounded-full"
                onClick={increment}
              >
                <ChevronRightIcon className="h-4 w-4 text-gray-500" />
              </button>
            </div>
          </div>
          {type === 'date' && (
            <>
              <div className="flex flex-wrap mb-3 -mx-1">
                {DAYS.map((day, i) => (
                  <div
                    key={i}
                    style={{ width: '14.26%' }}
                    className="px-1"
                  >
                    <div className="text-gray-800 font-medium text-center text-xs">
                      {day}
                    </div>
                  </div>
                ))}
              </div>
              <div className="flex flex-wrap -mx-1">
                {blankDays.map((_, i) => (
                  <div
                    key={i}
                    style={{ width: '14.26%' }}
                    className="text-center border p-1 border-transparent text-sm"
                  ></div>
                ))}
                {dayCount.map((d, i) => (
                  <div
                    key={i}
                    style={{ width: '14.26%' }}
                    className="px-1 mb-1"
                  >
                    <div
                      onClick={() => setDateValue(d)}
                      className={`cursor-pointer text-center text-sm rounded-full leading-loose transition ease-in-out duration-100 ${
                        isToday(d)
                          ? 'bg-primary text-white'
                          : isToday2(d)
                            ? 'text-blue-500 hover:bg-gray-200'
                            : 'text-gray-700 hover:bg-gray-200'
                      }`}
                    >
                      {d}
                    </div>
                  </div>
                ))}
              </div>
            </>
          )}
          {type === 'month' && (
            <div className="flex flex-wrap -mx-1">
              {Array(12)
                .fill(null)
                .map((_, i) => (
                  <div
                    key={i}
                    onClick={setMonthValue(i)}
                    style={{ width: '25%' }}
                  >
                    <div
                      className={`cursor-pointer p-5 font-semibold text-center text-sm rounded-lg hover:bg-gray-200 ${
                        isSelectedMonth(i)
                          ? 'bg-primary text-white'
                          : 'text-gray-700 hover:bg-gray-200'
                      }`}
                    >
                      {format(
                        new Date(
                          datepickerHeaderDate.getFullYear(),
                          i,
                          datepickerHeaderDate.getDate()
                        ),
                        'MMM'
                      )}
                    </div>
                  </div>
                ))}
            </div>
          )}
          {type === 'year' && (
            <div className="flex flex-wrap -mx-1">
              {Array(12)
                .fill(null)
                .map((_, i) => (
                  <div
                    key={datepickerHeaderDate.getFullYear() + i - 6}
                    onClick={setYearValue(datepickerHeaderDate.getFullYear() + i - 6)}
                    style={{ width: '25%' }}
                  >
                    <div
                      className={`cursor-pointer py-5 font-semibold text-center text-sm rounded-lg hover:bg-gray-200 ${
                        isSelectedYear(datepickerHeaderDate.getFullYear() + i - 6)
                          ? 'bg-primary text-white'
                          : 'text-gray-700 hover:bg-gray-200'
                      }`}
                    >
                      {format(
                        new Date(
                          datepickerHeaderDate.getFullYear() + i - 6,
                          datepickerHeaderDate.getMonth(),
                          datepickerHeaderDate.getDate()
                        ),
                        'yyyy'
                      )}
                    </div>
                  </div>
                ))}
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default InputDate
