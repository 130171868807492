/* eslint-disable no-unused-vars */
/* eslint-disable no-use-before-define */
import { ComponentProps } from 'react'
import { RouteObject } from 'react-router-dom'

// global

export type HeroIcon = (props: ComponentProps<'svg'>) => JSX.Element
export type Countries = 'AF' | 'AL' | 'DZ' | 'AS' | 'AD' | 'AO' | 'AI' | 'AQ' | 'AG' | 'AR' | 'AM' | 'AW' | 'AU' | 'AT' | 'AZ' | 'BS' | 'BH' | 'BD' | 'BB' | 'BY' | 'BE' | 'BZ' | 'BJ' | 'BM' | 'BT' | 'BO' | 'BQ' | 'BA' | 'BW' | 'BV' | 'BR' | 'IO' | 'BN' | 'BG' | 'BF' | 'BI' | 'KH' | 'CM' | 'CA' | 'CV' | 'KY' | 'CF' | 'TD' | 'CL' | 'CN' | 'CX' | 'CC' | 'CO' | 'KM' | 'CG' | 'CD' | 'CK' | 'CR' | 'HR' | 'CU' | 'CW' | 'CY' | 'CZ' | 'CI' | 'DK' | 'DJ' | 'DM' | 'DO' | 'EC' | 'EG' | 'SV' | 'GQ' | 'ER' | 'EE' | 'ET' | 'FK' | 'FO' | 'FJ' | 'FI' | 'FR' | 'GF' | 'PF' | 'TF' | 'GA' | 'GM' | 'GE' | 'DE' | 'GH' | 'GI' | 'GR' | 'GL' | 'GD' | 'GP' | 'GU' | 'GT' | 'GG' | 'GN' | 'GW' | 'GY' | 'HT' | 'HM' | 'VA' | 'HN' | 'HK' | 'HU' | 'IS' | 'IN' | 'ID' | 'IR' | 'IQ' | 'IE' | 'IM' | 'IL' | 'IT' | 'JM' | 'JP' | 'JE' | 'JO' | 'KZ' | 'KE' | 'KI' | 'KP' | 'KR' | 'KW' | 'KG' | 'LA' | 'LV' | 'LB' | 'LS' | 'LR' | 'LY' | 'LI' | 'LT' | 'LU' | 'MO' | 'MK' | 'MG' | 'MW' | 'MY' | 'MV' | 'ML' | 'MT' | 'MH' | 'MQ' | 'MR' | 'MU' | 'YT' | 'MX' | 'FM' | 'MD' | 'MC' | 'MN' | 'ME' | 'MS' | 'MA' | 'MZ' | 'MM' | 'NA' | 'NR' | 'NP' | 'NL' | 'NC' | 'NZ' | 'NI' | 'NE' | 'NG' | 'NU' | 'NF' | 'MP' | 'NO' | 'OM' | 'PK' | 'PW' | 'PS' | 'PA' | 'PG' | 'PY' | 'PE' | 'PH' | 'PN' | 'PL' | 'PT' | 'PR' | 'QA' | 'RO' | 'RU' | 'RW' | 'RE' | 'BL' | 'SH' | 'KN' | 'LC' | 'MF' | 'PM' | 'VC' | 'WS' | 'SM' | 'ST' | 'SA' | 'SN' | 'RS' | 'SC' | 'SL' | 'SG' | 'SX' | 'SK' | 'SI' | 'SB' | 'SO' | 'ZA' | 'GS' | 'SS' | 'ES' | 'LK' | 'SD' | 'SR' | 'SJ' | 'SZ' | 'SE' | 'CH' | 'SY' | 'TW' | 'TJ' | 'TZ' | 'TH' | 'TL' | 'TG' | 'TK' | 'TO' | 'TT' | 'TN' | 'TR' | 'TM' | 'TC' | 'TV' | 'UG' | 'UA' | 'AE' | 'GB' | 'US' | 'UM' | 'UY' | 'UZ' | 'VU' | 'VE' | 'VN' | 'VG' | 'VI' | 'WF' | 'EH' | 'YE' | 'ZM' | 'ZW' | 'AX'
export type Currencies = 'AFN' | 'ALL' | 'DZD' | 'USD' | 'EUR' | 'AOA' | 'XCD' | 'AQD' | 'ARS' | 'AMD' | 'AWG' | 'AUD' | 'AZN' | 'BSD' | 'BHD' | 'BDT' | 'BBD' | 'BYR' | 'BZD' | 'XOF' | 'BMD' | 'BTN' | 'BOB' | 'BAM' | 'BWP' | 'BRL' | 'BND' | 'BGN' | 'BIF' | 'KHR' | 'XAF' | 'CAD' | 'CVE' | 'KYD' | 'CLP' | 'CNY' | 'COP' | 'KMF' | 'CDF' | 'NZD' | 'CRC' | 'HRK' | 'CUP' | 'CZK' | 'DKK' | 'DJF' | 'DOP' | 'EGP' | 'ERN' | 'ETB' | 'FKP' | 'FJD' | 'XPF' | 'GMD' | 'GEL' | 'GHS' | 'GIP' | 'GTQ' | 'GBP' | 'GNF' | 'GYD' | 'HTG' | 'HNL' | 'HKD' | 'HUF' | 'ISK' | 'INR' | 'IDR' | 'IRR' | 'IQD' | 'ILS' | 'JMD' | 'JPY' | 'JOD' | 'KZT' | 'KES' | 'KPW' | 'KRW' | 'KWD' | 'KGS' | 'LAK' | 'LBP' | 'LSL' | 'LRD' | 'LYD' | 'CHF' | 'MOP' | 'MKD' | 'MGA' | 'MWK' | 'MYR' | 'MVR' | 'MRO' | 'MUR' | 'MXN' | 'MDL' | 'MNT' | 'MAD' | 'MZN' | 'MMK' | 'NAD' | 'NPR' | 'NIO' | 'NGN' | 'NOK' | 'OMR' | 'PKR' | 'PAB' | 'PGK' | 'PYG' | 'PEN' | 'PHP' | 'PLN' | 'QAR' | 'RON' | 'RUB' | 'RWF' | 'SHP' | 'WST' | 'STD' | 'SAR' | 'RSD' | 'SCR' | 'SLL' | 'SGD' | 'SBD' | 'SOS' | 'ZAR' | 'LKR' | 'SDG' | 'SSP' | 'SRD' | 'SZL' | 'SEK' | 'SYP' | 'TWD' | 'TJS' | 'TZS' | 'THB' | 'TOP' | 'TTD' | 'TND' | 'TRY' | 'TMT' | 'UGX' | 'UAH' | 'AED' | 'UYU' | 'UZS' | 'VUV' | 'VEF' | 'VND' | 'YER' | 'ZMW' | 'ZWL'

export type Option = {
  value: string
  label: string
  extra?: any
}

export type Country = {
  name: string
  phoneCode: string
  code: Countries
  flag: string
  language?: string
  currency: Currencies
}

export type PageConfig = {
  route: RouteObject
  auth: (user?: User, activeCompany?: UCompany) => boolean
  lock?: (user?: User, activeCompany?: UCompany) => boolean
  layout?: 'AUTH' | 'APP'
}

// project

export type Roles = 'ADMIN' | 'MANAGER' | 'AGENT'
export type Languages = 'ES' | 'EN' | 'FR'

export type Address = {
  placeId: string
  label: string
  longitude: number
  latitude: number
}

export type User = {
  id: string
  name?: string
  email?: string
  phone?: string
  avatar?: string
  job?: string
  language?: Languages
  companies: UCompany[],
  createdAt: string
}

export type Company = {
  id: string
  name: string
  avatar?: string
  country: Countries
  plan: PlanTypes
  archivedAt?: string
  createdAt: string
}

export type UCompany = Company & {
  role: Roles
  job?: string
}

export type CustomersTypes = 'INDIVIDUAL' | 'COMPANY'

export type Customer = {
  id: string
  name: string
  email: string
  phone: string
  type: CustomersTypes
  identificationNumber: string
  address?: Address
  // notifications: boolean
  createdAt: string
}

export type Content = {
  id: string
  note?: string
  pictures?: string[]
  interventionId: string
  intervention?: Intervention
  createdAt: string
  createdById: string
  createdBy?: User
}

export enum InterventionStatus {
  PLANNED,
  STARTED,
  FINISHED
}

export type Intervention = {
  // ids
  id: string
  // informations
  status: keyof typeof InterventionStatus
  // requested status
  // requestDescription?: string
  // requestedAt: string
  name?: string
  address: Address
  customerId: string
  customer?: Customer
  companyId: string
  company?: Company
  // planned status
  beginAt: string
  endAt: string
  groupId?: string
  firstDay?: string
  lastDay?: string
  planDescription?: string
  agentsIds: string[]
  agents?: User[]
  plannedAt: string
  plannedById: string
  plannedBy?: User
  // started status
  contents?: Content[]
  startedAt?: string
  startedById?: string
  startedBy?: User
  // finished status
  finishedAt?: string
  finishedById?: string
  finishedBy?: User
  // options
  geolocation: boolean
  // signature: boolean
  // create
  createdAt: string
  createdById: string
  createdBy?: User
  // update
  updatedAt?: string
  updatedById?: string
  updatedBy?: User
}

/// /////////////////////////////////////////////////////////////

export type Offer = {
  id: PlanTypes
  name: string
  favorite: boolean
  price: number
  vat: boolean
  admin: number | '∞'
  agents: number | '∞'
  manager: number | '∞'
  features: {
    dashboard: boolean
    invoicing: boolean
    geolocation: boolean
    signature: boolean
    recurring: boolean
  }
}

export type PlanCurrencies = 'USD' | 'EUR'

export type PlanIntervals = 'MONTH' | 'YEAR'

export type PlanTypes = 'BASIC' | 'PRO' | 'ENTERPRISE'

export type Plan = {
  type: PlanTypes
  interval: PlanIntervals
  currency: PlanCurrencies
}
